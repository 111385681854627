import { defineStore } from 'pinia';
import { ModalsName } from '@/hooks/useModalClient';
export var STRATEGY;
(function (STRATEGY) {
    STRATEGY["and"] = "and";
    STRATEGY["or"] = "or";
})(STRATEGY || (STRATEGY = {}));
export var HISTORY_ACTIONS;
(function (HISTORY_ACTIONS) {
    HISTORY_ACTIONS["active"] = "active";
    HISTORY_ACTIONS["inactive"] = "inactive";
    HISTORY_ACTIONS["value"] = "value";
    HISTORY_ACTIONS["delete"] = "delete";
    HISTORY_ACTIONS["add"] = "add";
})(HISTORY_ACTIONS || (HISTORY_ACTIONS = {}));
export var HISTORY_SECTIONS;
(function (HISTORY_SECTIONS) {
    HISTORY_SECTIONS["script"] = "script";
    HISTORY_SECTIONS["objection"] = "objection";
    HISTORY_SECTIONS["callback"] = "callback";
    HISTORY_SECTIONS["case"] = "case";
    HISTORY_SECTIONS["comment"] = "comment";
    HISTORY_SECTIONS["error"] = "error";
    HISTORY_SECTIONS["letter"] = "letter";
    HISTORY_SECTIONS["meeting"] = "meeting";
    HISTORY_SECTIONS["sms"] = "sms";
    HISTORY_SECTIONS["status"] = "status";
    HISTORY_SECTIONS["task"] = "task";
    HISTORY_SECTIONS["client"] = "client";
})(HISTORY_SECTIONS || (HISTORY_SECTIONS = {}));
export const useScriptStore = defineStore('script', {
    state: () => {
        return {
            counter: 0,
            script: {
                id: '2729dd02-e634-4499-a14a-7449905db96c', // 121
                name: 'Входящий скрипт для новых клиентов',
                version: 1,
                description: '',
                type: 'lead',
                сreatedDate: 1710856884,
            },
            sections: [
                {
                    id: 'ab8e87c5-18b6-48c9-9c16-bb019a005d7d', // 1
                    name: 'Приветствие диспетчера',
                    description: 'В этом разделе менеджер уточняет основные данные клиента.',
                    priority: 10,
                    steps: [
                        {
                            id: '2467961e-5846-4818-ae48-b652fb0d6566',
                            required: false,
                            html: "Здравствуйте,&Tab;'Волкова перезвон'?<br>Университет Синергия, меня зовут <value user></value>. <a target=\"_blank\" href=\"https://corp.synergy.ru/crm/deal/tablet.php?dealId=<value id='111112'></value>\">Ссылка на Карточку абитуриента сделки</a>",
                            help: 'Уточните имя клиента и отредактируйте его справа, в окне клиента',
                            next: [{
                                    id: 'c4e519b0-ae88-46ed-9168-9fb387a4d0e9',
                                    step: '1b8c8ff6-df4e-4192-97f8-7ba0e65027e2', // 2
                                    strategy: '',
                                    conditions: null
                                }],
                            start: true,
                            props: {
                                stepNum: '1',
                            }
                        },
                        {
                            id: '1b8c8ff6-df4e-4192-97f8-7ba0e65027e2',
                            elementId: '111112',
                            required: true,
                            html: 'Вы оставляли заявку на нашем сайте, Вы интересовались        <element id="111112"></element>      Верно?',
                            next: [{
                                    id: 'e7a19e40-1335-4611-9c79-cfc84a1f364f',
                                    step: '4fff1c5f-8434-4cb1-85e0-3112dd441255', // 3
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '2',
                            }
                        },
                        {
                            id: '4fff1c5f-8434-4cb1-85e0-3112dd441255',
                            elementId: '111113',
                            required: true,
                            html: 'В каком городе Вы проживаете? <span class="space"></span> <element id="111113"></element>',
                            next: [{
                                    id: 'baeb990a-e33a-43c2-91d5-2f04b5e79ed1',
                                    step: '4fff1c5f-8434-4cb1-85e0-3112dd441244', // 5
                                    strategy: '',
                                    conditions: null
                                }],
                            stop: true,
                            props: {
                                stepNum: '3',
                            }
                        },
                        {
                            id: '4fff1c5f-8434-4cb1-85e0-3112dd441244',
                            elementId: '1111147777777',
                            required: true,
                            html: 'В каком городе Вы проживаете? <span class="space"></span> <element id="1111147777777"></element>',
                            next: [{
                                    id: 'baeb990a-e33a-43c2-91d5-2f04b5e79ed1',
                                    step: '4fff1c5f-8434-4cb1-85e0-3112dd441245353', // 5
                                    strategy: '',
                                    conditions: null
                                }],
                            stop: true,
                            props: {
                                stepNum: '3',
                            }
                        },
                        {
                            id: '4fff1c5f-8434-4cb1-85e0-3112dd441245353',
                            required: true,
                            html: 'В каком городе Вы проживаете? <span class="space"></span> <value id="1111147777777"></value>',
                            next: [{
                                    id: 'baeb990a-e33a-43c2-91d5-2f04b5e79ed1',
                                    step: '34608150-49dd-4eb3-875a-81d373feaade', // 5
                                    strategy: '',
                                    conditions: null
                                }],
                            stop: true,
                            props: {
                                stepNum: '3',
                            }
                        },
                        {
                            id: '34608150-49dd-4eb3-875a-81d373feaade',
                            elementId: '111115',
                            required: true,
                            html: 'Скажите, пожалуйста, <value client></value>, Вы готовы сейчас получить консультацию от специалиста <b>Приёмной комиссии</b>?<br><element id="111115"></element>',
                            next: [
                                {
                                    id: 'df2af154-5e75-4d15-925c-56b9c094ad7d',
                                    step: '989ae188-fa67-4477-9b58-e8ca6e46154c', // 6
                                    strategy: '',
                                    conditions: null
                                },
                                {
                                    id: 'aec25136-5145-4d1a-85af-d96bf32c1529',
                                    step: '918c29a6-e885-477d-9159-59f101a66849', // 8
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: 'c35d8b50-5b7d-4914-b57a-e466b50c77e8',
                                            elementId: '111115',
                                            value: ['2'],
                                            strategy: 'and'
                                        }
                                    ]
                                },
                            ],
                            props: {
                                stepNum: '5',
                            }
                        },
                        {
                            id: '989ae188-fa67-4477-9b58-e8ca6e46154c',
                            required: false,
                            html: 'Хорошо, <value client></value>, ожидайте на линии, соединяю Вас со специалистом!',
                            next: [{
                                    id: 'b60db418-0f7e-4bb9-97cf-a1601f0114e9',
                                    step: '8ec35fc6-05b8-4e5c-bcec-052f38f881ce', // 7
                                    strategy: '',
                                    conditions: null,
                                }],
                            props: {
                                stepNum: '6',
                            }
                        },
                        {
                            id: '8ec35fc6-05b8-4e5c-bcec-052f38f881ce',
                            elementId: '111116',
                            required: true,
                            stop: true,
                            html: 'Рекомендуемые линии<br> <element id="111116"></element>',
                            next: [{
                                    id: 'a138b269-f3d8-4ed1-91d1-26cdce8ad568',
                                    step: '77511e31-3d81-4856-9121-3a4e8f5f7d75', // 22
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '7',
                            }
                        },
                        {
                            id: '918c29a6-e885-477d-9159-59f101a66849',
                            elementId: '111117',
                            required: true,
                            html: 'Укажите статус клиента <span class="space"></span> <element id="111117"></element>',
                            next: [{
                                    id: '5b56a705-aa2d-4994-a12d-5911ea68d7e1',
                                    step: '9764a4ef-152e-40b9-884e-433defab41ee', // 9
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '504a70c2-5d9b-49af-a52c-13c4b38d3f58',
                                            elementId: '111117',
                                            value: ['2'],
                                            strategy: 'and',
                                        }
                                    ]
                                }],
                            props: {
                                stepNum: '8',
                            }
                        },
                        {
                            id: '9764a4ef-152e-40b9-884e-433defab41ee',
                            elementId: '111118',
                            required: true,
                            html: 'В какое время Вы готовы получить консультацию? <span class="space"></span> <element id="111118"></element>',
                            next: [{
                                    id: '3f4b278e-3ca5-41aa-a950-1aa5a6a7acfd',
                                    step: '2ae8912c-c7dc-4ac4-a574-b56fcec0b07c', // 10
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '9'
                            }
                        },
                        {
                            id: '2ae8912c-c7dc-4ac4-a574-b56fcec0b07c',
                            required: false,
                            html: 'Хорошо, <value client></value>, ожидайте звонка <b><value id="111118"/></b>. Всего доброго!',
                            props: {
                                stepNum: '10',
                            }
                        },
                    ]
                },
                {
                    id: '6d6eb202-168d-452c-8643-84e9386e7e7d', // 2
                    name: 'Параметры продукта',
                    description: 'На этом шаге менеджер уточняет основные параметры продукта.',
                    priority: 20,
                    steps: [
                        {
                            id: '77511e31-3d81-4856-9121-3a4e8f5f7d75',
                            required: false,
                            html: 'Добрый день, <value client></value>. Меня зовут <value user></value> - сотрудник Приемной комиссии Университет "Синергия". Вижу, что Вы из <value id="111113"></value> и Вас интересует поступление в <value id="111112"></value>. Все верно?',
                            next: [{
                                    id: '59b0c2ea-126c-45ca-ace0-26f77c15a611',
                                    step: '9dee8b06-0038-488d-b432-5f798e0d2ebd', // 23
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '22',
                            }
                        },
                        {
                            id: '9dee8b06-0038-488d-b432-5f798e0d2ebd',
                            required: false,
                            html: 'Я обязательно Вам расскажу, как поступить и сколько будет стоить обучение, но чтобы подобрать оптимальный вариант, разрешите мне задать Вам несколько вопросов, договорились?',
                            next: [{
                                    id: 'fbe5e65e-ee56-4223-8478-611470004514',
                                    step: 'e32c29e9-8f66-44d3-93ac-9892e83c846f', //24
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '23',
                            }
                        },
                        {
                            id: 'e32c29e9-8f66-44d3-93ac-9892e83c846f',
                            elementId: '111121',
                            required: true,
                            html: 'Скажите, Вы для себя подбираете обучение?<br><element id="111121"></element>',
                            next: [{
                                    id: '7ae308ee-fb27-40e6-96ba-d2a5936941f7',
                                    step: '37aa550c-4f05-478b-9d86-a59e7c10d18d', // 26
                                    conditions: null,
                                    strategy: ''
                                },
                                {
                                    id: '0d998a49-eb4d-4618-8b6d-afe7f32b1492',
                                    step: 'bba37ddb-eec8-43bb-a536-d7189d48b04e', // 25
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: 'abda777b-e4b1-486b-8e30-720ad6e9e917',
                                            elementId: '111121',
                                            value: ['2'],
                                            strategy: 'and'
                                        }
                                    ]
                                }],
                            props: {
                                stepNum: '24',
                            }
                        },
                        {
                            id: 'bba37ddb-eec8-43bb-a536-d7189d48b04e',
                            elementId: '111122',
                            required: true,
                            html: 'Кем Вы приходитесь абитуриенту? <element id="111122"></element>',
                            next: [{
                                    id: 'e9e84f32-b5f2-4a82-8f73-f7a05496f7ff',
                                    step: '37aa550c-4f05-478b-9d86-a59e7c10d18d', // 26
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '25',
                            }
                        },
                        {
                            id: '37aa550c-4f05-478b-9d86-a59e7c10d18d',
                            elementId: '111123',
                            required: true,
                            html: 'Какой уровень образования у Вас (абитуриента) на данный момент?<br><element id="111123"></element>',
                            next: [{
                                    id: 'b2f0b6bb-a86e-4882-bb79-9030630bfc77',
                                    step: '6470eafd-f929-4942-b61f-0e18341cb08e', // 27
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '7b9ca2f0-7f5c-4086-b952-2eef02905803',
                                            elementId: '111123',
                                            value: ['3', '4', '5'],
                                            strategy: 'or'
                                        }
                                    ]
                                }],
                            props: {
                                stepNum: '26',
                            }
                        },
                        {
                            id: '6470eafd-f929-4942-b61f-0e18341cb08e',
                            elementId: '111124',
                            required: true,
                            html: 'Вы (абитуриент) сдавали ЕГЭ?<br><element id="111124"></element>',
                            next: [{
                                    id: '4329894a-ad03-45a5-b242-9097543fac13',
                                    step: '34f3a574-af46-463d-98da-906da0481e17', // 28
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: 'f7378752-b891-4d40-82bd-fb20cdd8ec82',
                                            elementId: '111124',
                                            value: ['1'],
                                            strategy: 'and',
                                        }
                                    ]
                                }],
                            props: {
                                stepNum: '27',
                            }
                        },
                        {
                            id: '34f3a574-af46-463d-98da-906da0481e17',
                            elementId: '111125',
                            required: true,
                            html: 'В каком году? <element id="111125"></element>',
                            next: [{
                                    id: '294282b2-e1f4-46fe-800e-ab9bb94007ac',
                                    step: '97e04032-3900-4325-89bc-e831144fa635', // 29
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '28',
                            }
                        },
                        {
                            id: '97e04032-3900-4325-89bc-e831144fa635',
                            elementId: '111111',
                            required: true,
                            html: 'Какие предметы сдавали<br><element id="111111"></element>',
                            next: [
                                {
                                    id: '94b3a483-b5d0-4d74-835f-f935380ca9e0',
                                    step: 'fb4625d6-408e-4450-af9e-172900c3df61', // 30
                                    strategy: 'or',
                                    conditions: [
                                        {
                                            id: 'e662e5ea-3299-4218-b669-0ca23a3fc1b3',
                                            elementId: '111111',
                                            value: ['1'],
                                            strategy: 'or',
                                        }
                                    ],
                                },
                                {
                                    id: '5cc6cc65-0412-4fb7-8473-6e361c994c09',
                                    step: '9fe84625-2037-43da-991c-964a42475be1', // 31
                                    strategy: 'or',
                                    conditions: [
                                        {
                                            id: '30aa444b-d938-47f8-8304-acc8d836836a',
                                            elementId: '111111',
                                            value: ['2'],
                                            strategy: 'or',
                                        }
                                    ]
                                },
                                {
                                    id: '62696277-cda7-412c-8b4e-f37c1744d5a5',
                                    step: 'ad32fd00-9001-478f-9f3d-09f943a51a6e', // 32
                                    strategy: 'or',
                                    conditions: [
                                        {
                                            id: '323cbff1-1708-4887-8195-9e54bad888ad',
                                            elementId: '111111',
                                            value: ['3'],
                                            strategy: 'or',
                                        }
                                    ]
                                },
                                {
                                    id: '01f6d96a-4eb4-46d9-a790-a32e05bcf758',
                                    step: '6e933e62-7647-473b-a33e-4ae9f2ac16dd', // 33
                                    strategy: 'or',
                                    conditions: [
                                        {
                                            id: '9d2e9a4a-7417-46de-b233-62493dfe8817',
                                            elementId: '111111',
                                            value: ['4'],
                                            strategy: 'or',
                                        }
                                    ]
                                },
                                {
                                    id: '3e4b3411-f121-4bef-b89c-6212094850e6',
                                    step: '1b64044a-b5ab-40b9-b269-ecd333379d08', // 34
                                    strategy: 'or',
                                    conditions: [
                                        {
                                            id: '9578301a-aaf1-466a-8485-4821a7ecaafb',
                                            elementId: '111111',
                                            value: ['5'],
                                            strategy: 'or',
                                        }
                                    ]
                                }
                            ],
                            stop: true,
                            props: {
                                stepNum: '29',
                            }
                        },
                        {
                            id: 'fb4625d6-408e-4450-af9e-172900c3df61',
                            elementId: '111209',
                            required: true,
                            html: 'Введите баллы по <b>Русскому языку</b> <span class="space"></span> <element id="111209"></element>.',
                            group: 'test',
                            next: [{
                                    id: 'c8eed12a-2e6b-4fd1-961d-e71fa1a121a7',
                                    step: '02a14062-144e-40b4-a936-ffcec7fcf4dc', // 35
                                    strategy: '',
                                    conditions: null
                                }],
                            stop: true,
                            props: {
                                stepNum: '30',
                            }
                        },
                        {
                            id: '9fe84625-2037-43da-991c-964a42475be1',
                            elementId: '111210',
                            required: true,
                            html: 'Введите баллы по <b>Математике</b> <span class="space"></span> <element id="111210"></element>.',
                            group: 'test',
                            next: [{
                                    id: '9cae7b06-ab85-4759-9b12-ea6ad216e191',
                                    step: '02a14062-144e-40b4-a936-ffcec7fcf4dc', // 35
                                    strategy: '',
                                    conditions: null
                                }],
                            stop: true,
                            props: {
                                stepNum: '31',
                            }
                        },
                        {
                            id: 'ad32fd00-9001-478f-9f3d-09f943a51a6e',
                            elementId: '111211',
                            required: true,
                            html: 'Введите баллы по <b>Химии</b> <span class="space"></span> <element id="111211"></element>.',
                            group: 'test',
                            next: [{
                                    id: 'e66adf52-4a21-4d05-b491-86503e3b1063',
                                    step: '02a14062-144e-40b4-a936-ffcec7fcf4dc', // 35
                                    strategy: '',
                                    conditions: null
                                }],
                            stop: true,
                            props: {
                                stepNum: '32',
                            }
                        },
                        {
                            id: '6e933e62-7647-473b-a33e-4ae9f2ac16dd',
                            elementId: '111212',
                            required: true,
                            html: 'Введите баллы по <b>Физике</b> <span class="space"></span> <element id="111212"></element>.',
                            group: 'test',
                            next: [{
                                    id: 'ce2b75f6-c70e-478d-b2e5-60bf4c178262',
                                    step: '02a14062-144e-40b4-a936-ffcec7fcf4dc', // 35
                                    strategy: '',
                                    conditions: null
                                }],
                            stop: true,
                            props: {
                                stepNum: '33',
                            }
                        },
                        {
                            id: '1b64044a-b5ab-40b9-b269-ecd333379d08',
                            elementId: '111213',
                            required: true,
                            html: 'Введите баллы по <b>Биологии</b> <span class="space"></span> <element id="111213"></element>.',
                            group: 'test',
                            next: [{
                                    id: 'e125d767-8226-43ec-8319-8ca4464b16c2',
                                    step: '02a14062-144e-40b4-a936-ffcec7fcf4dc', // 35
                                    strategy: '',
                                    conditions: null,
                                }],
                            stop: true,
                            props: {
                                stepNum: '34',
                            }
                        },
                        {
                            id: '02a14062-144e-40b4-a936-ffcec7fcf4dc',
                            elementId: '111126',
                            required: false,
                            html: 'Проходной балл набран или <a step="1b8c8ff6-df4e-4192-97f8-7ba0e65027e2">подобрать альтернативу</a>?<br><element id="111126"></element>',
                            next: [{
                                    id: '2c874b55-09ef-482b-ab73-ed9d8e2f06b2',
                                    step: 'e384228e-f82a-47a6-9605-eea32b2199d2', // 301
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '77fbbeed-ccd7-4872-93ae-66fc0fb1807d',
                                            elementId: '111126',
                                            value: ['1'],
                                            strategy: 'and',
                                        }
                                    ]
                                }],
                            props: {
                                stepNum: '35',
                            }
                        },
                    ]
                },
                {
                    id: 'f81cdaec-86ce-4923-9730-0eb98af184c1', // 3
                    name: 'Презентация продукта',
                    description: 'Рассказываем о продукте и его особенностях.',
                    priority: 30,
                    steps: [
                        {
                            id: 'e384228e-f82a-47a6-9605-eea32b2199d2',
                            required: false,
                            html: 'Клиент <value client></value> из <value id="111113"></value> интересовался <value id="111112"></value>',
                            next: [{
                                    id: 'fa30d7b4-3cb1-4239-b804-66d06c1de414',
                                    step: '0df8b45f-fe6f-4de5-bbcf-d3b0ea37bbcc', // 302
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '301',
                            }
                        },
                        {
                            id: '0df8b45f-fe6f-4de5-bbcf-d3b0ea37bbcc',
                            required: true,
                            elementId: '111131',
                            html: 'Подскажите, <value client></value>, с нашим университетом знакомы? <br>Давайте я Вам кратко расскажу об университете?<br> <element id="111131"></element>',
                            next: [{
                                    id: '50a0dcd9-f2c5-4e95-b6d1-278a1bf10fb7',
                                    step: '72721350-839b-4238-a9cf-47ed83142ec9', // 304
                                    strategy: '',
                                    conditions: null
                                },
                                {
                                    id: '69669658-5df8-487a-91c4-6f3936f7db28',
                                    step: 'c0011984-dcf6-489f-80a9-64ff4f0c891a', // 303
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: 'df4316d9-c89d-454e-a531-68b02b01e92c',
                                            elementId: '111131',
                                            value: ['1'],
                                            strategy: 'and',
                                        }
                                    ]
                                }],
                            props: {
                                stepNum: '302',
                            }
                        },
                        {
                            id: 'c0011984-dcf6-489f-80a9-64ff4f0c891a',
                            required: false,
                            html: '<b>Во-первых,</b> «Синергия» - это крупный образовательный холдинг, который ведет образовательную деятельность более 30 лет.  Холдинг существует, как в РФ, так и за рубежом. У нас есть представительства в 80 городах РФ, в странах бывшего СССР, и даже кампус в Дубай. <br><br><b>Во-вторых,</b> Мы предоставляем нашим студентам возможность работать и нарабатывать опыт уже во время обучения. Это позволяет по окончанию обучения быть высококвалифицированными специалистами с ОПЫТОМ работы. <br><br>Так же, выпускники нашего вуза получают не только диплом государственного образца, который катируется любым работодателем в России! Но и Европриложение, которое позволит строить карьеру не только в России, но и в 47 странах мира. <br><br>К тому же, наше обучение – это не набор скучной теории, а интереснейшие мастер-классы, тренинги, интерактивные игры, знаменитые БИЗНЕС-ФОРУМЫ с легендарными личностями и многое другое.',
                            next: [{
                                    id: 'e6980fd4-34ee-4f44-990f-e9c23c956f48',
                                    step: '72721350-839b-4238-a9cf-47ed83142ec9', // 304
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '303',
                            }
                        },
                        {
                            id: '72721350-839b-4238-a9cf-47ed83142ec9',
                            required: true,
                            elementId: '111132',
                            html: 'Давайте, я Вам расскажу о выбранном факультете? <br> <element id="111132"></element>',
                            next: [{
                                    id: '2749c975-3904-4699-a65b-a315b3d700b1',
                                    step: 'dcef184b-605b-48de-ab1a-47f5a5b5be0f', // 401
                                    strategy: '',
                                    conditions: null
                                },
                                {
                                    id: 'ac20d6ce-f29e-42e5-b171-a8dc666e1f6c',
                                    step: 'a863977e-fb03-4451-8db0-a0febb675db7', // 305
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '2bdb9af7-e68f-47a6-914b-f0bcaf492003',
                                            elementId: '111132',
                                            value: ['1'],
                                            strategy: 'and',
                                        }
                                    ]
                                }],
                            props: {
                                stepNum: '304',
                            }
                        },
                        {
                            id: 'a863977e-fb03-4451-8db0-a0febb675db7',
                            required: true,
                            elementId: '111133',
                            html: 'Филиал (где планируете учиться)? <element id="111133"></element>',
                            next: [{
                                    id: '190d605e-c99c-4cdf-93db-a33a73895330',
                                    step: 'c8f74cc8-1803-4f0a-ac06-e5be3dc605d9', // 306
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '305',
                            }
                        },
                        {
                            id: 'c8f74cc8-1803-4f0a-ac06-e5be3dc605d9',
                            required: true,
                            elementId: '111134',
                            html: 'Какое образование планируете получать? <element id="111134"></element>',
                            next: [{
                                    id: '61d6fe90-3bfc-4f32-b37e-b1e95d78de05',
                                    step: '9c93ac3c-2802-4baa-9771-45ea69087293', // 307
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '306',
                            }
                        },
                        {
                            id: '9c93ac3c-2802-4baa-9771-45ea69087293',
                            required: true,
                            elementId: '111135',
                            html: 'Подуровень образования? <element id="111135"></element>',
                            next: [{
                                    id: '884b9ca1-4d13-46f6-9ff4-25b149266514',
                                    step: '5b240b49-5029-40c2-aa14-58b85ca2dec7', // 308
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: 'b34fa273-815d-4276-880a-93861c9c01bc',
                                            elementId: '111133',
                                            value: ['1', '2', '4'],
                                            strategy: 'and',
                                        },
                                    ],
                                },
                                {
                                    id: 'aac55b2b-a4ca-49d9-9157-fde690ab654f',
                                    step: 'aa3b1b11-4a06-44dd-8c4c-c41ce54ac752', // 309
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '3c67fd4e-76b2-4b2a-8336-f6a38113e307',
                                            elementId: '111133',
                                            value: ['3'],
                                            strategy: 'and',
                                        },
                                    ]
                                },
                                {
                                    id: '7d576067-e490-457a-956b-56ed2c0bb276',
                                    step: '101e9971-399b-404d-9e63-abd221004eee', // 310
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '1de859a2-d68d-4ee9-afd0-50ba6faae77a',
                                            elementId: '111133',
                                            value: ['5'],
                                            strategy: 'and',
                                        },
                                    ]
                                }],
                            props: {
                                stepNum: '307',
                            }
                        },
                        {
                            id: '5b240b49-5029-40c2-aa14-58b85ca2dec7',
                            required: true,
                            elementId: '111136',
                            html: 'Выбранный факультет <element id="111136"></element>',
                            next: [{
                                    id: 'd5eabd8a-f17d-4af8-a828-4a5692eb3189',
                                    step: '4bd141de-48c7-4bdc-9aa3-9101d5d3f9d2', // 311
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '7fa4c31d-0efc-4fe6-be1d-3a4aa8c0f39a',
                                            elementId: '111136',
                                            value: ['2'],
                                            strategy: 'and',
                                        },
                                    ]
                                },
                                {
                                    id: 'c0393b0e-f2e3-4029-a7f2-f0bf30cfd002',
                                    step: 'a61d1ba4-685f-4f2d-8ef7-e96c6098cd10', // 312
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: 'f766511c-ce9a-48dd-b138-5a4a14ddd4ca',
                                            elementId: '111136',
                                            value: ['1'],
                                            strategy: 'and',
                                        },
                                    ]
                                },
                                {
                                    id: '8a12241d-beac-4238-aa26-9cbd78762e14',
                                    step: '2545fe75-a473-4792-927e-28fb2d543377', // 313
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: 'a423e68e-c972-45cc-a9c0-4e77067f2af9',
                                            elementId: '111136',
                                            value: ['3'],
                                            strategy: 'and',
                                        },
                                    ]
                                },
                                {
                                    id: 'eb24b5e7-751d-40a3-9f66-0087396d8f45',
                                    step: '87271a15-0fe2-4ca5-b87f-58464b2c3171', // 314
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: 'deecf844-11d3-4be4-89db-c8a21a29893d',
                                            elementId: '111136',
                                            value: ['4'],
                                            strategy: 'and',
                                        },
                                    ]
                                },
                                {
                                    id: '4713b859-5258-43cc-bceb-be070c722499',
                                    step: 'a4c176cc-0562-4436-a1a3-548fbbce272f', // 315
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '6ce3a655-97a0-4761-b1be-2b31de0f71fc',
                                            elementId: '111136',
                                            value: ['5'],
                                            strategy: 'and',
                                        },
                                    ]
                                }
                            ],
                            props: {
                                stepNum: '308',
                            }
                        },
                        {
                            id: 'aa3b1b11-4a06-44dd-8c4c-c41ce54ac752',
                            required: true,
                            elementId: '111137',
                            html: 'Выбранный факультет <element id="111137"></element>',
                            next: [{
                                    id: '93f3203f-02cc-40fc-a117-17a4964c0eef',
                                    step: '4bd141de-48c7-4bdc-9aa3-9101d5d3f9d2', // 311
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '0cf47216-552c-44ba-a8e5-5e6ae08a68fc',
                                            elementId: '111137',
                                            value: ['1'],
                                            strategy: 'and',
                                        },
                                    ]
                                },
                                {
                                    id: '1e477c8a-9f9a-4fed-9fa7-0bc40eed3994',
                                    step: '87271a15-0fe2-4ca5-b87f-58464b2c3171', // 314
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '5d541d0c-2e5b-4505-91c8-176001ea2030',
                                            elementId: '111137',
                                            value: ['2'],
                                            strategy: 'and',
                                        },
                                    ]
                                },
                                {
                                    id: 'd7c07895-9618-43f9-af2f-9a18cfde09a1',
                                    step: 'a4c176cc-0562-4436-a1a3-548fbbce272f', // 315
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '38a7df35-e602-4f02-b49c-f91857d59948',
                                            elementId: '111137',
                                            value: ['3'],
                                            strategy: 'and',
                                        },
                                    ]
                                }
                            ],
                            props: {
                                stepNum: '309',
                            }
                        },
                        {
                            id: '101e9971-399b-404d-9e63-abd221004eee',
                            required: true,
                            elementId: '111138',
                            html: 'Выбранный факультет <element id="111138"></element>',
                            next: [{
                                    id: '35fd3f44-4dd3-45d2-950e-b0b633b4f758',
                                    step: '4bd141de-48c7-4bdc-9aa3-9101d5d3f9d2', // 311
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '2a2a02fe-ae29-4bc6-878e-3baf6c613f3e',
                                            elementId: '111138',
                                            value: ['1'],
                                            strategy: 'and',
                                        },
                                    ]
                                },
                                {
                                    id: '371660ab-867d-494e-a669-6964fe92a103',
                                    step: '2545fe75-a473-4792-927e-28fb2d543377', // 313
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '88c6afe7-d3fd-4019-a439-5d6366015aeb',
                                            elementId: '111138',
                                            value: ['2'],
                                            strategy: 'and',
                                        },
                                    ]
                                },
                                {
                                    id: '72627a8f-9318-4398-89fb-fc1f404e4635',
                                    step: 'a4c176cc-0562-4436-a1a3-548fbbce272f', // 315
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '345feba3-6329-4a09-9678-d42cdec07ddd',
                                            elementId: '111138',
                                            value: ['3'],
                                            strategy: 'and',
                                        },
                                    ]
                                }
                            ],
                            props: {
                                stepNum: '310',
                            }
                        },
                        {
                            id: '4bd141de-48c7-4bdc-9aa3-9101d5d3f9d2',
                            required: true,
                            elementId: '111139',
                            html: 'Какую выбрали специальность? <element id="111139"></element>',
                            next: [{
                                    id: '77b1017a-8dfe-4b28-835b-f954bb98abba',
                                    step: 'ca32b810-b22e-4aa4-a6db-690d51391dd6', // 316
                                    strategy: '',
                                    conditions: null,
                                }],
                            props: {
                                stepNum: '311',
                            }
                        },
                        {
                            id: 'a61d1ba4-685f-4f2d-8ef7-e96c6098cd10',
                            required: true,
                            elementId: '111140',
                            html: 'Какую выбрали специальность? <element id="111140"></element>',
                            next: [{
                                    id: '1eefd438-a516-4c6b-919e-a8bc28248490',
                                    step: 'ca32b810-b22e-4aa4-a6db-690d51391dd6', // 316
                                    strategy: '',
                                    conditions: null,
                                }],
                            props: {
                                stepNum: '312',
                            }
                        },
                        {
                            id: '2545fe75-a473-4792-927e-28fb2d543377',
                            required: true,
                            elementId: '111141',
                            html: 'Какую выбрали специальность? <element id="111141"></element>',
                            next: [{
                                    id: '8474cd6b-1275-4d85-acd4-b129d157caee',
                                    step: 'ca32b810-b22e-4aa4-a6db-690d51391dd6', // 316
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '313',
                            }
                        },
                        {
                            id: '87271a15-0fe2-4ca5-b87f-58464b2c3171',
                            required: true,
                            elementId: '111142',
                            html: 'Какую выбрали специальность? <element id="111142"></element>',
                            next: [{
                                    id: '5f78e2ab-a8ca-4f67-9259-2feb224d4f89',
                                    step: 'ca32b810-b22e-4aa4-a6db-690d51391dd6', // 316
                                    strategy: '',
                                    conditions: null,
                                }],
                            props: {
                                stepNum: '314',
                            }
                        },
                        {
                            id: 'a4c176cc-0562-4436-a1a3-548fbbce272f',
                            required: true,
                            elementId: '111143',
                            html: 'Какую выбрали специальность? <element id="111143"></element>',
                            next: [{
                                    id: 'b5d7da14-fb96-4f35-97d6-7a647d8ff196',
                                    step: 'ca32b810-b22e-4aa4-a6db-690d51391dd6', // 316
                                    strategy: '',
                                    conditions: null,
                                }],
                            props: {
                                stepNum: '315',
                            }
                        },
                        {
                            id: 'ca32b810-b22e-4aa4-a6db-690d51391dd6',
                            required: false,
                            html: 'Преимущества программы - это старт для тех, кто видит свое призвание в медицине и помощи людям. В Университете "Синергия" вы сможете получить врачебную специальность по востребованным направлениям и успешно начать карьеру',
                            next: [{
                                    id: '83c159cd-fa9c-482d-a02b-734a07558e29',
                                    step: 'b0f9c20d-22c1-427d-aadc-1ec3b2778d99', // 317
                                    strategy: '',
                                    conditions: null,
                                }],
                            props: {
                                stepNum: '316',
                            }
                        },
                        {
                            id: 'b0f9c20d-22c1-427d-aadc-1ec3b2778d99',
                            required: false,
                            elementId: '111144',
                            html: '<element id="111144"></element>',
                            next: [{
                                    id: '0041bf65-76b2-461e-95ac-7296c0cbc435',
                                    step: 'b4ed4bed-e80a-4b9a-b0a8-0e75436ca4b7', // 318
                                    strategy: '',
                                    conditions: null,
                                }],
                            props: {
                                stepNum: '317',
                            }
                        },
                        {
                            id: 'b4ed4bed-e80a-4b9a-b0a8-0e75436ca4b7',
                            required: true,
                            elementId: '111145',
                            html: 'Вам подходит данная программа или <a step="305">рассказать о другом факультете</a>? <br> <element id="111145"></element>',
                            next: [{
                                    id: '40c0809b-d733-4014-931d-8612fef09679',
                                    step: 'dcef184b-605b-48de-ab1a-47f5a5b5be0f', // 401
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: '9f599dba-2dee-4ede-aab0-e9a2acc0074f',
                                            elementId: '111145',
                                            value: ['1'],
                                            strategy: 'and',
                                        },
                                    ]
                                }],
                            props: {
                                stepNum: '318',
                            }
                        },
                    ]
                },
                {
                    id: 'a89121c6-e798-4b73-9d95-c176797a01ca', // 4
                    name: 'Стоимость обучения',
                    description: 'Уточняем стоимость',
                    priority: 40,
                    steps: [
                        {
                            id: 'dcef184b-605b-48de-ab1a-47f5a5b5be0f',
                            required: true,
                            elementId: '111112',
                            html: '<value client></value>, давайте уточним, на каком факультете вы остановили свой выбор <element id="111112"></element>',
                            next: [{
                                    id: 'e99c3996-be4a-460d-9f81-05082d2fdb1d',
                                    step: '086aa69b-6acb-4db1-9a20-e1bc90d58722', // 402
                                    strategy: 'or',
                                    conditions: [
                                        {
                                            id: '177303f5-7741-4d30-afbc-cae5f79f1b79',
                                            elementId: '111112',
                                            value: ['1'],
                                            strategy: 'or',
                                        },
                                    ]
                                }, {
                                    id: '553fa640-92ec-4158-9bb9-310389250248',
                                    step: '5eed126e-2791-4854-9f85-65031b33f990', // 403
                                    strategy: 'or',
                                    conditions: [
                                        {
                                            id: '69359146-8be8-4c41-ab66-debecb855fb3',
                                            elementId: '111112',
                                            value: ['2'],
                                            strategy: 'or',
                                        },
                                    ]
                                }, {
                                    id: 'bfebd59d-ac93-4ce1-94c2-5406bf3d1043',
                                    step: '7ee5299b-b532-46c4-bd42-f5c2be21d36f', // 404
                                    strategy: 'or',
                                    conditions: [
                                        {
                                            id: 'f4a1edfc-f845-4072-b07b-3bb516201772',
                                            elementId: '111112',
                                            value: ['3'],
                                            strategy: 'or',
                                        },
                                    ]
                                }, {
                                    id: '745e5d61-2289-4582-89ec-e063c725b9dc',
                                    step: 'e6a37ef1-fea5-4bdb-a71d-309908d3e72b', // 405
                                    strategy: 'or',
                                    conditions: [
                                        {
                                            id: '86d1abce-dcce-41b4-a1c4-ff76eeeb67a3',
                                            elementId: '111112',
                                            value: ['4'],
                                            strategy: 'or',
                                        },
                                    ]
                                }, {
                                    id: 'abf9dfd2-4d52-4434-8953-cb26a5960343',
                                    step: '5614ef13-fadc-4101-8f81-e3f823a5ddff', // 406
                                    strategy: 'or',
                                    conditions: [
                                        {
                                            id: '0a182dad-a9b3-4890-a2dc-b494deeee590',
                                            elementId: '111112',
                                            value: ['5'],
                                            strategy: 'or',
                                        },
                                    ]
                                }],
                            props: {
                                stepNum: '401',
                            }
                        },
                        {
                            id: '086aa69b-6acb-4db1-9a20-e1bc90d58722',
                            required: false,
                            html: '<value client></value>, теперь давайте я вам расскажу о стоимости обучения и способах оплаты. <br>Длительность обучения на <b>Медицинском факультете</b> будет составлять 4 года.<br>Стоимость обучения за весь период 400 000 рублей.<br>Важно, что при оплате за весь период сразу вы фиксируете стоимость обучения и больше к вопросу оплаты не возвращаетесь.',
                            next: [{
                                    id: '2f3da14d-7f90-45a4-9cba-5d730b4f1dc7',
                                    step: '13399adf-af7b-4752-b1e0-329060417071', // 407
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '402',
                            }
                        },
                        {
                            id: '5eed126e-2791-4854-9f85-65031b33f990',
                            required: false,
                            html: '<value client></value>, теперь давайте я вам расскажу о стоимости обучения и способах оплаты. <br>Длительность обучения на <b>Финансовом факультете</b> будет составлять 5 лет.<br>Стоимость обучения за весь период 500 000 рублей.<br>Важно, что при оплате за весь период сразу вы фиксируете стоимость обучения и больше к вопросу оплаты не возвращаетесь.',
                            next: [{
                                    id: '4dfc082e-d161-4eba-ad8f-12a7f54b6b20',
                                    step: '13399adf-af7b-4752-b1e0-329060417071', // 407
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '403',
                            }
                        },
                        {
                            id: '7ee5299b-b532-46c4-bd42-f5c2be21d36f',
                            required: false,
                            html: '<value client></value>, теперь давайте я вам расскажу о стоимости обучения и способах оплаты. <br>Длительность обучения на <b>Факультете маркетинга и рекламы</b> будет составлять 4,5 года.<br>Стоимость обучения за весь период 450 000 рублей.<br>Важно, что при оплате за весь период сразу вы фиксируете стоимость обучения и больше к вопросу оплаты не возвращаетесь.',
                            next: [{
                                    id: '00aceffd-079d-4d09-a10a-a5111cb2c90f',
                                    step: '13399adf-af7b-4752-b1e0-329060417071', // 407
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '404',
                            }
                        },
                        {
                            id: 'e6a37ef1-fea5-4bdb-a71d-309908d3e72b',
                            required: false,
                            html: '<value client></value>, теперь давайте я вам расскажу о стоимости обучения и способах оплаты. <br>Длительность обучения на <b>Факультете журналистики</b> будет составлять 4 года.<br>Стоимость обучения за весь период 350 000 рублей.<br>Важно, что при оплате за весь период сразу вы фиксируете стоимость обучения и больше к вопросу оплаты не возвращаетесь.',
                            next: [{
                                    id: 'cffa1283-1980-4767-8288-2d0d08f0caa9',
                                    step: '13399adf-af7b-4752-b1e0-329060417071', // 407
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '405',
                            }
                        },
                        {
                            id: '5614ef13-fadc-4101-8f81-e3f823a5ddff',
                            required: false,
                            html: '<value client></value>, теперь давайте я вам расскажу о стоимости обучения и способах оплаты. <br>Длительность обучения на <b>Факультете искусства</b> будет составлять 5 лет.<br>Стоимость обучения за весь период 470 000 рублей.<br>Важно, что при оплате за весь период сразу вы фиксируете стоимость обучения и больше к вопросу оплаты не возвращаетесь.',
                            next: [{
                                    id: '905be881-b649-422d-876b-55f68a863fec',
                                    step: '13399adf-af7b-4752-b1e0-329060417071', // 407
                                    strategy: '',
                                    conditions: null,
                                }],
                            props: {
                                stepNum: '406',
                            }
                        },
                        {
                            id: '13399adf-af7b-4752-b1e0-329060417071',
                            required: true,
                            elementId: '111150',
                            html: 'Такой вариант оплаты вам подходит или предложить другие варианты? <br><element id="111150"></element>',
                            next: [{
                                    id: 'e286cf76-ebbe-426d-b73f-8ef61ed2568b',
                                    step: '139fa3f8-d454-417d-861b-4ea88b50ce62', // 408
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: 'ecaf57bd-7a05-45f3-8d2c-9b7b1f63847c',
                                            elementId: '111150',
                                            value: ['2'],
                                            strategy: 'and',
                                        },
                                    ]
                                }, {
                                    id: '93e2bcf4-d778-4a37-94a0-856ee7dc34a9',
                                    step: '83e2b69d-a0b2-4f96-90fe-9c1717f955aa', // 500
                                    strategy: 'and',
                                    conditions: [
                                        {
                                            id: 'eec13e98-0782-450d-9ad8-ee5809c5f88a',
                                            elementId: '111150',
                                            value: ['1'],
                                            strategy: 'and',
                                        },
                                    ]
                                }],
                            props: {
                                stepNum: '407',
                            }
                        },
                        {
                            id: '139fa3f8-d454-417d-861b-4ea88b50ce62',
                            required: true,
                            elementId: '111151',
                            html: '<element id="111151"></element>',
                            next: [{
                                    id: '91aaadba-3c50-4855-802e-89b404c76cb7',
                                    step: '83e2b69d-a0b2-4f96-90fe-9c1717f955aa', // 500
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '408',
                            }
                        },
                    ]
                },
                {
                    id: 'b28a73ea-bc9c-4970-8660-36def6608b71', // 5
                    name: 'Создание сделки',
                    priority: 50,
                    description: 'Формируем сделку',
                    steps: [
                        {
                            id: '83e2b69d-a0b2-4f96-90fe-9c1717f955aa',
                            required: false,
                            html: 'Давайте уточним контактные данные плательщика',
                            next: [{
                                    id: '757378f3-1834-4826-8640-c7ea4f6b9c2a',
                                    step: '4f350af8-a5f2-48fd-810d-7e81e2fd7921', // 501
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '500',
                            }
                        },
                        {
                            id: '4f350af8-a5f2-48fd-810d-7e81e2fd7921',
                            required: true,
                            elementId: '111201',
                            html: 'Фамилия<br><element id="111201"></element>',
                            next: [{
                                    id: '85cf487f-996f-4beb-ae4a-31d5d9d476de',
                                    step: '5b8c21d4-1f87-4ecc-9751-a03604f614df', // 502
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '501'
                            }
                        },
                        {
                            id: '5b8c21d4-1f87-4ecc-9751-a03604f614df',
                            required: true,
                            elementId: '111202',
                            html: 'Имя<br><element id="111202"></element>',
                            next: [{
                                    id: '88f38e77-00d9-4803-bab9-3d02d8658ba0',
                                    step: '4209a02c-b961-4b7b-a8b5-5b1683a6fe05', // 503
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '502'
                            }
                        },
                        {
                            id: '4209a02c-b961-4b7b-a8b5-5b1683a6fe05',
                            required: false,
                            elementId: '111203',
                            html: 'Отчество<br><element id="111203"></element>',
                            next: [{
                                    id: 'b84123c5-ad2e-4ddc-94a8-ed8cd1654092',
                                    step: '2b17f0ef-8a89-4564-aed3-3ecca9da2664', // 504
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '503'
                            }
                        },
                        {
                            id: '2b17f0ef-8a89-4564-aed3-3ecca9da2664',
                            required: true,
                            elementId: '111204',
                            html: 'Дата рождения<br><element id="111204"></element>',
                            next: [{
                                    id: 'd2af1c96-e28a-4fa6-a573-e59e41fd0634',
                                    step: 'd733d4cb-2c8c-474f-a1ff-1fc17267558b', // 505
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '504'
                            }
                        },
                        {
                            id: 'd733d4cb-2c8c-474f-a1ff-1fc17267558b',
                            required: true,
                            elementId: '111205',
                            html: 'E-mail<br><element id="111205"></element>',
                            next: [{
                                    id: '6a205d83-e96b-4c9a-8c38-48d416d69a78',
                                    step: 'ac8d7769-569e-4d0b-b728-6438d4259786', // 506
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '505',
                            }
                        },
                        {
                            id: 'ac8d7769-569e-4d0b-b728-6438d4259786',
                            required: true,
                            elementId: '111206',
                            html: 'Телефон<br><element id="111206"></element>',
                            next: [{
                                    id: '99aec6ff-44c1-4b27-9467-fe81f5d4776b',
                                    step: 'a3456038-1446-4e36-9554-195d06920212', // 507
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '506',
                            }
                        },
                        {
                            id: 'a3456038-1446-4e36-9554-195d06920212',
                            required: true,
                            elementId: '111207',
                            html: 'Вам будет удобнее получить перечень документов для поступления, учебный план и презентацию факультета для ознакомления на почту или в мессенджер?<br><element id="111207"></element>',
                            next: [{
                                    id: '788726e1-3762-4b03-8097-67d87883aa72',
                                    step: 'f0de8bc1-0ca6-4d8f-afcc-1b30c8c506eb', // 508
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '507'
                            }
                        },
                        {
                            id: 'f0de8bc1-0ca6-4d8f-afcc-1b30c8c506eb',
                            required: false,
                            html: 'Спасибо! Хорошего дня!',
                            props: {
                                stepNum: '508',
                            }
                        },
                    ]
                },
            ],
            elements: [
                {
                    id: '111109',
                    name: 'Число',
                    value: null,
                    type: 2,
                },
                {
                    id: '111110',
                    name: 'Время',
                    value: { hours: 1, minutes: 5 },
                    type: 20,
                },
                {
                    id: '111111',
                    name: 'Баллы',
                    value: '',
                    type: 10,
                    props: {
                        stop: true,
                        options: [
                            {
                                id: '1',
                                name: 'Русский язык'
                            },
                            {
                                id: '2',
                                name: 'Математика'
                            },
                            {
                                id: '3',
                                name: 'Химия'
                            },
                            {
                                id: '4',
                                name: 'Физика'
                            },
                            {
                                id: '5',
                                name: 'Биология'
                            }
                        ],
                    }
                },
                {
                    id: '111112',
                    name: 'Факультет',
                    value: ['1'],
                    type: 10,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Медицинский факультет'
                            },
                            {
                                id: '2',
                                name: 'Финансовый факультет'
                            },
                            {
                                id: '3',
                                name: 'Факультет маркетинга и рекламы'
                            },
                            {
                                id: '4',
                                name: 'Факультет журналистики'
                            },
                            {
                                id: '5',
                                name: 'Факультет искусства'
                            },
                        ],
                    }
                },
                {
                    id: '111113',
                    name: 'Город',
                    value: '["13487", "15232"]',
                    type: 33,
                    props: {
                        stop: true,
                    }
                },
                {
                    id: '1111147777777',
                    name: 'Город2',
                    value: '["24158", "5016"]',
                    type: 33,
                    props: {
                        stop: true,
                    }
                },
                {
                    id: '111115',
                    name: 'Консультация',
                    value: '1',
                    type: 13,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Готов',
                            },
                            {
                                id: '2',
                                name: 'Не готов'
                            }
                        ],
                    }
                },
                {
                    id: '111116',
                    name: 'Рекомендуемые линии',
                    value: '',
                    type: 29,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Наименование линии номер один',
                                phone: '3171',
                            },
                            {
                                id: '2',
                                name: 'Наименование линии номер два',
                                phone: '8288',
                            },
                            {
                                id: '3',
                                name: 'Наименование линии номер три',
                                phone: '0003',
                            }
                        ],
                        func: async (str) => {
                            console.log(str);
                        },
                    }
                },
                {
                    id: '111117',
                    name: 'Статус',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Отказ'
                            },
                            {
                                id: '2',
                                name: 'Перезвонить'
                            }
                        ],
                    }
                },
                {
                    id: '111118',
                    name: 'Время для перезвона',
                    value: '',
                    type: 18,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111121',
                    name: 'Для себя?',
                    value: '',
                    type: 13,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Да',
                            },
                            {
                                id: '2',
                                name: 'Нет'
                            }
                        ],
                    }
                },
                {
                    id: '111122',
                    name: 'Степень родства',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Отец'
                            },
                            {
                                id: '2',
                                name: 'Мать'
                            },
                            {
                                id: '3',
                                name: 'Сестра'
                            },
                            {
                                id: '4',
                                name: 'Брат'
                            },
                            {
                                id: '5',
                                name: 'Дедушка'
                            },
                            {
                                id: '6',
                                name: 'Бабушка'
                            },
                            {
                                id: '7',
                                name: 'Дядя'
                            },
                            {
                                id: '8',
                                name: 'Тетя'
                            },
                        ],
                    }
                },
                {
                    id: '111123',
                    name: 'Уровень образования',
                    value: '',
                    type: 13,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Еще учусь (5-10 класс)',
                            },
                            {
                                id: '2',
                                name: '9 классов'
                            },
                            {
                                id: '3',
                                name: '11 классов'
                            },
                            {
                                id: '4',
                                name: 'СПО/НПО'
                            },
                            {
                                id: '5',
                                name: 'Высшее'
                            }
                        ],
                    }
                },
                {
                    id: '111124',
                    name: 'ЕГЭ',
                    value: '',
                    type: 13,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Да',
                            },
                            {
                                id: '2',
                                name: 'Нет'
                            }
                        ],
                    }
                },
                {
                    id: '111125',
                    name: 'ЕГЭ год',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: '2001',
                            },
                            {
                                id: '2',
                                name: '2002'
                            },
                            {
                                id: '3',
                                name: '2003'
                            },
                            {
                                id: '4',
                                name: '2004'
                            },
                            {
                                id: '5',
                                name: '2005'
                            },
                            {
                                id: '6',
                                name: '2006'
                            },
                            {
                                id: '7',
                                name: '2007'
                            },
                            {
                                id: '8',
                                name: '2008'
                            },
                            {
                                id: '9',
                                name: '2009'
                            },
                            {
                                id: '10',
                                name: '2010'
                            },
                        ],
                    }
                },
                {
                    id: '111126',
                    name: 'Проходной балл',
                    value: '',
                    type: 13,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Да',
                            },
                            {
                                id: '2',
                                name: 'Нет',
                            }
                        ],
                    }
                },
                {
                    id: '111131',
                    name: 'Об университете',
                    value: '',
                    type: 13,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Да',
                            },
                            {
                                id: '2',
                                name: 'Нет'
                            }
                        ],
                    }
                },
                {
                    id: '111132',
                    name: 'О факультете',
                    value: '',
                    type: 13,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Да',
                            },
                            {
                                id: '2',
                                name: 'Нет'
                            }
                        ],
                    }
                },
                {
                    id: '111133',
                    name: 'Филиал',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Москва',
                            },
                            {
                                id: '2',
                                name: 'Самара'
                            },
                            {
                                id: '3',
                                name: 'Краснодар'
                            },
                            {
                                id: '4',
                                name: 'Казань'
                            },
                            {
                                id: '5',
                                name: 'Новосибирск'
                            },
                        ],
                    }
                },
                {
                    id: '111134',
                    name: 'Образование',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Высшее образование',
                            },
                            {
                                id: '2',
                                name: 'Среднее специальное'
                            },
                        ],
                    }
                },
                {
                    id: '111135',
                    name: 'Подуровень образования',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Бакалавр',
                            },
                            {
                                id: '2',
                                name: 'Магистратура'
                            },
                        ],
                    }
                },
                {
                    id: '111136',
                    name: 'Факультет',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Медицинский факультет'
                            },
                            {
                                id: '2',
                                name: 'Финансовый факультет'
                            },
                            {
                                id: '3',
                                name: 'Факультет маркетинга и рекламы'
                            },
                            {
                                id: '4',
                                name: 'Факультет журналистики'
                            },
                            {
                                id: '5',
                                name: 'Факультет искусства'
                            }
                        ],
                    }
                },
                {
                    id: '111137',
                    name: 'Факультет Краснодар',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Финансовый факультет'
                            },
                            {
                                id: '2',
                                name: 'Факультет журналистики'
                            },
                            {
                                id: '3',
                                name: 'Факультет искусства'
                            }
                        ],
                    }
                },
                {
                    id: '111138',
                    name: 'Факультет Новосибирск',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Финансовый факультет'
                            },
                            {
                                id: '2',
                                name: 'Факультет маркетинга и рекламы'
                            },
                            {
                                id: '3',
                                name: 'Факультет искусства'
                            }
                        ],
                    }
                },
                {
                    id: '111139',
                    name: 'Специальность Финансовый факультет',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Финансы',
                            },
                            {
                                id: '2',
                                name: 'Бухгалтерия'
                            },
                        ],
                    }
                },
                {
                    id: '111140',
                    name: 'Специальность Медицинский факультет',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Лечебное дело',
                            },
                            {
                                id: '2',
                                name: 'Хирургия'
                            },
                        ],
                    }
                },
                {
                    id: '111141',
                    name: 'Специальность маркетинга и рекламы',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Маркетинг',
                            },
                            {
                                id: '2',
                                name: 'Реклама'
                            },
                        ],
                    }
                },
                {
                    id: '111142',
                    name: 'Специальность факультета журналистики',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Журналистика',
                            },
                            {
                                id: '2',
                                name: 'Редактура'
                            },
                        ],
                    }
                },
                {
                    id: '111143',
                    name: 'Специальность факультета искусства',
                    value: '',
                    type: 8,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Искусствоведение',
                            },
                            {
                                id: '2',
                                name: 'Реставрация'
                            },
                        ],
                    }
                },
                {
                    id: '111144',
                    name: 'Презентация Медицинского факультета',
                    value: '',
                    type: 26,
                    props: {
                        stop: false,
                        label: 'Ссылка на презентацию факультета',
                        download: true,
                        href: 'index.html'
                    }
                },
                {
                    id: '111145',
                    name: 'Рассказать о другом факультете?',
                    value: '',
                    type: 13,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Подходит',
                            },
                            {
                                id: '2',
                                name: 'Не подходит',
                            }
                        ],
                    }
                },
                {
                    id: '111150',
                    name: 'Предложить другие варианты оплаты?',
                    value: '',
                    type: 13,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Подходит',
                            },
                            {
                                id: '2',
                                name: 'Другие варианты',
                            }
                        ],
                    }
                },
                {
                    id: '111151',
                    name: 'Другие варианты оплаты',
                    value: '',
                    type: 13,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'Скидка',
                            },
                            {
                                id: '2',
                                name: 'Ученическая беспроцентная рассрочка',
                            },
                            {
                                id: '3',
                                name: 'Оплата за семестр',
                            },
                            {
                                id: '4',
                                name: 'Оплата за полугодие',
                            }
                        ],
                    }
                },
                {
                    id: '111201',
                    name: 'Фамилия',
                    value: '',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111202',
                    name: 'Имя',
                    value: '',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111203',
                    name: 'Отчество',
                    value: '',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111204',
                    name: 'Дата рождения',
                    value: '',
                    type: 17,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111205',
                    name: 'E-mail',
                    value: '',
                    type: 30,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111206',
                    name: 'Телефон',
                    value: '',
                    type: 3,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111207',
                    name: 'Куда получить документы?',
                    value: '',
                    type: 16,
                    props: {
                        stop: false,
                        options: [
                            {
                                id: '1',
                                name: 'WhatsApp',
                            },
                            {
                                id: '2',
                                name: 'Email',
                            },
                        ]
                    }
                },
                {
                    id: '111209',
                    name: 'Русский язык',
                    value: '',
                    type: 5,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111210',
                    name: 'Математика',
                    value: '',
                    type: 2,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111211',
                    name: 'Химия',
                    value: '',
                    type: 2,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111212',
                    name: 'Физика',
                    value: '',
                    type: 2,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111213',
                    name: 'Биология',
                    value: '',
                    type: 2,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '111214',
                    name: 'Динамический селект',
                    value: '',
                    type: 10,
                    props: {
                        stop: false,
                        bindElementId: '111125',
                        options: {
                            1: [
                                {
                                    id: '1',
                                    name: 'Искусствоведение',
                                },
                                {
                                    id: '2',
                                    name: 'Реставрация'
                                }
                            ],
                            2: [
                                {
                                    id: '1',
                                    name: 'Плитка',
                                },
                                {
                                    id: '2',
                                    name: 'Паркет'
                                },
                                {
                                    id: '3',
                                    name: 'Искусствоведение',
                                },
                                {
                                    id: '4',
                                    name: 'Реставрация',
                                }
                            ],
                            3: [
                                {
                                    id: '1',
                                    name: 'Камень',
                                },
                                {
                                    id: '2',
                                    name: 'Дерево'
                                }
                            ]
                        },
                    },
                },
                {
                    id: '10003',
                    name: 'Имя клиента',
                    value: 'Анастасия',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10004',
                    name: 'Отчество клиента',
                    value: 'Сергеевна',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10005',
                    name: 'Фамилия клиента',
                    value: 'Петрова',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10016',
                    name: 'Город клиента',
                    value: 'Москва',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10099',
                    name: 'Образование клиента',
                    value: '',
                    type: 10,
                    props: {
                        options: [
                            {
                                id: "3038",
                                name: "1 класс"
                            },
                            {
                                id: "3039",
                                name: "2 класс"
                            },
                            {
                                id: "3040",
                                name: "3 класс"
                            },
                            {
                                id: "3041",
                                name: "4 класс"
                            },
                            {
                                id: "214",
                                name: "5 класс"
                            },
                            {
                                id: "215",
                                name: "6 класс"
                            },
                            {
                                id: "216",
                                name: "7 класс"
                            },
                            {
                                id: "167",
                                name: "8 класс"
                            },
                            {
                                id: "161",
                                name: "9 класс"
                            },
                            {
                                id: "165",
                                name: "10 класс"
                            },
                            {
                                id: "163",
                                name: "11 класс (Выпускной)"
                            },
                            {
                                id: "173",
                                name: "1-й курс"
                            },
                            {
                                id: "169",
                                name: "2-й курс"
                            },
                            {
                                id: "171",
                                name: "3-й курс"
                            },
                            {
                                id: "983",
                                name: "4-й курс"
                            },
                            {
                                id: "984",
                                name: "5-й курс"
                            }
                        ],
                        stop: false,
                    }
                },
                {
                    id: '10009',
                    name: 'Откуда клиента',
                    value: 'Основной сайт Университета',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10045',
                    name: 'Телефон клиента',
                    value: '["+79884754788", "+79884754789"]',
                    type: 3,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10046',
                    name: 'Почта клиента',
                    value: null,
                    type: 30,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10070',
                    name: 'ФИО матери клиента',
                    value: '',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10128',
                    name: 'Почта матери клиента',
                    value: '',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10068',
                    name: 'Телефон матери клиента',
                    value: '',
                    type: 3,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10071',
                    name: 'ФИО отца клиента',
                    value: 'Петров Василий Григорьевич',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10069',
                    name: 'Телефон отца клиента',
                    value: '+79884754200',
                    type: 3,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: '10127',
                    name: 'Почта отца клиента',
                    value: '',
                    type: 1,
                    props: {
                        stop: false,
                    }
                },
                {
                    id: "a7781c1d-64bd-4f68-8fe4-a0a5a04718e4",
                    name: "Статус Лида",
                    value: "",
                    type: 8,
                    props: {
                        options: [
                            {
                                id: "NEW",
                                name: "Новый лид",
                            },
                            {
                                id: "ASSIGNED",
                                name: "Передан менеджеру",
                            },
                            {
                                id: "CANNOT_CONTACT",
                                name: "Недозвон",
                            },
                            {
                                id: "RECALL",
                                name: "Перезвон",
                                modalName: 'recall'
                            },
                            {
                                id: "ON_HOLD",
                                name: "На перераспределение",
                            },
                            {
                                id: "SPAM",
                                name: "Спам",
                            },
                            {
                                id: "TEST",
                                name: "Тест",
                            },
                            {
                                id: "DOUBLE",
                                name: "Дубль"
                            },
                            {
                                id: "REPEAT",
                                name: "Повтор"
                            },
                            {
                                id: "NUMBER_ERROR",
                                name: "Ошибка номера"
                            },
                            {
                                id: "CONVERTED",
                                name: "Сделка создана"
                            },
                            {
                                id: "JUNK",
                                name: "Отказ"
                            }
                        ]
                    }
                },
                {
                    id: '2c297427-a3d4-4a2c-86f2-f6d5596fd4af',
                    name: 'Комментарий',
                    value: '',
                    type: 31,
                    props: {
                        stop: false,
                    }
                }
            ],
            knowledgeBaseId: 1,
            styles: '',
            objections: [
                {
                    id: '91de3831-9f8c-4346-9865-8573c6e5647c', // 1
                    name: 'Сейчас нет времени',
                    priority: 2,
                    steps: [
                        {
                            id: '595d562c-6593-4f3c-a90b-46b1f3ac3bcb',
                            required: false,
                            html: 'Хорошо, <value client></value>, ожидайте звонка <b><value id="111118"/></b>. Всего доброго!',
                            props: {
                                stepNum: '1011'
                            }
                        },
                        {
                            id: 'f8a52973-8ff4-4df7-ba7c-8e52f4d338c3',
                            elementId: '111118',
                            required: true,
                            html: 'В какое время Вы готовы получить консультацию? <span class="space"></span> <element id="111118"></element>',
                            next: [{
                                    id: 'b404ca46-a70f-4682-a325-bf0462147d00',
                                    step: '595d562c-6593-4f3c-a90b-46b1f3ac3bcb', // 1011
                                    strategy: '',
                                    conditions: null
                                }],
                            start: true,
                            props: {
                                stepNum: '1010'
                            }
                        },
                    ],
                },
                {
                    id: '89b78b9b-5f2b-41b8-b6bf-c217c9c3514a', // 2
                    name: 'У конкурентов дешевле',
                    priority: 1,
                    steps: [
                        {
                            id: 'cddb52ca-2703-44bf-8eae-37644d1b75f2',
                            required: true,
                            elementId: '111151',
                            html: 'Возможно, вам подойдут следующие варианты:<br><element id="111151"></element>',
                            start: true,
                            props: {
                                stepNum: '1006',
                            }
                        },
                    ]
                },
                {
                    id: '1de5ced6-5499-4f2b-8bbe-6868cb1429f9', // 3
                    name: 'Плохое настроение',
                    priority: 3,
                    steps: [
                        {
                            id: 'd9cba4b1-8524-434a-9fee-8e50af5775f4',
                            elementId: '111117',
                            required: true,
                            html: 'Укажите статус клиента <span class="space"></span> <element id="111117"></element>',
                            next: [{
                                    id: 'c301dfd8-ba5c-4d25-9188-f15dfddacf21',
                                    step: 'b7db80dc-043b-43fa-b810-08bf32140f5b', // 1000
                                    strategy: 'and',
                                    conditions: [{
                                            id: '8026bdba-78c3-4e4d-8841-f81d13e65394',
                                            elementId: '111117',
                                            value: ['2'],
                                            strategy: 'and',
                                        }]
                                }],
                            start: true,
                            props: {
                                stepNum: '1008',
                            }
                        },
                        {
                            id: '3cbebdb8-96cd-409e-ae02-60a23d54f515',
                            required: false,
                            html: 'Хорошо, <value client></value>, ожидайте звонка <b><value id="111118"/></b>. Всего доброго!',
                            props: {
                                stepNum: '1001'
                            }
                        },
                        {
                            id: 'b7db80dc-043b-43fa-b810-08bf32140f5b',
                            elementId: '111118',
                            required: true,
                            html: 'В какое время Вы готовы получить консультацию? <span class="space"></span> <element id="111118"></element>',
                            next: [{
                                    id: '34ec3b44-46fe-4849-923a-60e8f4924d44',
                                    step: '3cbebdb8-96cd-409e-ae02-60a23d54f515', // 1001
                                    strategy: '',
                                    conditions: null
                                }],
                            props: {
                                stepNum: '1000',
                            }
                        },
                    ],
                }
            ],
            comments: 'Дата: 04.11.2022 г. 12:28. Автор: Ирина Сомойленко. “Астрономия — одна из немногих наук, где непрофессионалы всё ещё могут играть активную роль привнесла свой вклад в ряд важных астрономических открытий”.  Дата: 04.11.2022 г. 12:28. Автор: Ирина Сомойленко. “Астрономия — одна из немногих наук, где непрофессионалы всё ещё могут играть активную роль привнесла свой вклад в ряд важных астрономических открытий”.  Дата: 04.11.2022 г. 12:28. Автор: Ирина Сомойленко. “Астрономия — одна из немногих наук, где непрофессионалы всё ещё могут играть активную роль привнесла свой вклад в ряд важных астрономических открытий”.',
            status: 'a7781c1d-64bd-4f68-8fe4-a0a5a04718e4',
            currentStatus: '',
            history: [
                {
                    messageCounter: 0,
                }
            ],
            clientData: {
                id: "d548a6e5-76e4-468b-872e-addb443fb7af",
                name: "10003",
                patronymic: "10004",
                surname: "10005",
                city: "10016",
                education: "10099",
                land: "10009",
                phone: "10045",
                mail: "10046",
                motherFio: "10070",
                motherPhone: "10068",
                motherMail: "10128",
                fatherFio: "10071",
                fatherPhone: "10069",
                fatherMail: "10127"
            },
            clientContactData: null,
            modals: [],
            clientId: 0,
            availableScripts: null,
            clientModals: Object.values(ModalsName),
            comment: {
                commentId: '2c297427-a3d4-4a2c-86f2-f6d5596fd4af',
                elements: ['111118', '111201', '111202', '111203', '111204', '111207', '111213', '10099', '10045', '10046', 'a7781c1d-64bd-4f68-8fe4-a0a5a04718e4']
            },
            initialElementsMap: new Map(),
            meta: {
                localHistorySave: false
            },
            analyticsSteps: new Map(),
        };
    },
    actions: {
        clear() {
            this.script = null;
            this.elements = null;
            this.objections = null;
            this.sections = [];
            this.history = [{ messageCounter: 0 }];
            this.knowledgeBaseId = null;
        }
    }
});
