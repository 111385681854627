import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "base-select__title" };
const _hoisted_2 = { class: "base-select__list" };
const _hoisted_3 = {
    key: 0,
    class: "base-select__search"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
    key: 0,
    class: "base-select__item"
};
const _hoisted_6 = {
    key: 1,
    class: "base-select__item"
};
const _hoisted_7 = {
    key: 3,
    class: "base-select__empty"
};
import { computed, nextTick, reactive, ref, toRef, watch } from "vue";
import { SELECT_MODE } from "@/components/@base/@types";
import { THEME } from "@/components/@types";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { clickOutside as vClickOutside } from "@/directives";
import { BaseIcon, BaseInput, BaseLoader } from "@/components/@base";
import _ from "lodash";
const __default__ = { name: 'BaseSelect' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        placeholder: { default: '' },
        options: { default: () => [] },
        modelValue: { default: null },
        required: { type: Boolean, default: false },
        theme: { default: THEME.light },
        width: { default: '100%' },
        height: { default: '176px' },
        disabled: { type: Boolean, default: false },
        mode: { default: SELECT_MODE.default },
        search: { type: Boolean, default: false },
        active: { default: null },
        loading: { type: Boolean, default: false },
        error: { type: Boolean, default: false }
    },
    emits: ["update:modelValue", "selected", "opened", "closed", "reach-end-y", "search", "save", "refresh-offset"],
    setup(__props, { emit: __emit }) {
        _useCssVars(_ctx => ({
            "a744a8e4": (props.width),
            "74ee6742": (props.height)
        }));
        const props = __props;
        const selectScroll = ref(undefined);
        const option = ref(undefined);
        const opts = props.options === null ? ref([]) : toRef(props, 'options');
        const data = reactive({
            isOpen: false,
            search: '',
            debounceTime: 1000,
            options: _.cloneDeep(opts.value)?.map(i => {
                i.disabled = false;
                return i;
            })
        });
        const emits = __emit;
        const selectedName = computed(() => {
            if (props.modelValue === null)
                return null;
            return props.options?.find(i => String(i.id) === String(props.modelValue))?.name ||
                String(props.active?.id) === String(props.modelValue) && props.active?.name || null;
        });
        const toggleOpen = () => {
            emits('refresh-offset');
            if (props.disabled)
                return;
            data.isOpen = !data.isOpen;
            if (data.isOpen)
                emits('opened');
            if (!data.isOpen)
                emits('closed');
            data.search = null;
            data.options.forEach(i => i.disabled = false);
            if (data.isOpen && props.mode === SELECT_MODE.scroll && option.value && option.value.length > 0 && selectScroll.value) {
                nextTick(() => {
                    const active = option.value.find((i) => i.classList.contains('active'));
                    if (!active)
                        return;
                    const coords = active.offsetTop;
                    selectScroll.value.$el.scrollTop = coords;
                });
            }
        };
        const close = () => {
            if (data.isOpen) {
                data.isOpen = false;
                emits('closed');
            }
        };
        const select = (item) => {
            if (item.disabled || item.blocked)
                return;
            emits('update:modelValue', item.id);
            emits('selected', item);
            emits('save', item);
            emits('refresh-offset');
            close();
        };
        const searchTerm = _.debounce(() => {
            if (props.search && data.isOpen) {
                emits('refresh-offset');
                emits('search', data.search);
            }
        }, data.debounceTime);
        const reachEndY = () => {
            if (selectScroll.value.ps.reach.y === 'end' && !props.loading) {
                emits('reach-end-y', data.search);
            }
        };
        watch(opts, next => {
            data.options = _.cloneDeep(next)?.map(i => {
                i.disabled = false;
                return i;
            }) || [];
        }, { deep: true });
        watch(() => opts.value, next => {
            data.options = _.cloneDeep(next)?.map(i => {
                i.disabled = false;
                return i;
            }) || [];
        }, { deep: true });
        return (_ctx, _cache) => {
            return (_unref(opts))
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["base-select", [
                            data.isOpen && 'opened',
                            props.theme,
                            props.required && 'required',
                            !props.modelValue && 'empty',
                            props.disabled && 'disabled',
                            props.error && 'error'
                        ]])
                }, [
                    (!_ctx.$slots.header)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "base-select__header",
                            onClick: toggleOpen
                        }, [
                            _createElementVNode("div", _hoisted_1, _toDisplayString(selectedName.value !== undefined && selectedName.value !== null ? selectedName.value : props.placeholder), 1),
                            _createVNode(_unref(BaseIcon), {
                                class: "base-select__chevron",
                                name: "chevron",
                                width: "16",
                                height: "17"
                            })
                        ]))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "base-select__custom-header",
                            onClick: toggleOpen
                        }, [
                            _renderSlot(_ctx.$slots, "header")
                        ])),
                    _createElementVNode("div", _hoisted_2, [
                        (_ctx.$slots.search)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _renderSlot(_ctx.$slots, "search")
                            ]))
                            : _createCommentVNode("", true),
                        (props.search)
                            ? (_openBlock(), _createBlock(_unref(BaseInput), {
                                key: 1,
                                class: "base-select__search",
                                modelValue: data.search,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((data.search) = $event)),
                                modelModifiers: { trim: true },
                                onInput: _unref(searchTerm),
                                placeholder: "Поиск",
                                theme: props.theme,
                                onClick: _cache[1] || (_cache[1] = _withModifiers(() => { }, ["prevent"]))
                            }, {
                                actions: _withCtx(() => [
                                    _createVNode(_unref(BaseIcon), {
                                        class: "base-select__search-icon",
                                        name: "search"
                                    })
                                ]),
                                _: 1
                            }, 8, ["modelValue", "onInput", "theme"]))
                            : _createCommentVNode("", true),
                        (props.options)
                            ? (_openBlock(), _createBlock(_unref(PerfectScrollbar), {
                                key: 2,
                                class: "base-select__scroll",
                                options: { minScrollbarLength: 20 },
                                onPsScrollDown: reachEndY,
                                ref_key: "selectScroll",
                                ref: selectScroll
                            }, {
                                default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (item) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                                            (!item.disabled)
                                                ? (_openBlock(), _createElementBlock("div", {
                                                    ref_for: true,
                                                    ref_key: "option",
                                                    ref: option,
                                                    class: _normalizeClass(["base-select__item", [String(props.modelValue) === String(item.id) && 'active', item.blocked && 'blocked']]),
                                                    key: item.id,
                                                    onClick: ($event) => (select(item))
                                                }, [
                                                    (_ctx.$slots.default)
                                                        ? _renderSlot(_ctx.$slots, "default", {
                                                            key: 0,
                                                            item: item
                                                        })
                                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                            _createTextVNode(_toDisplayString(item.name), 1)
                                                        ], 64))
                                                ], 10, _hoisted_4))
                                                : _createCommentVNode("", true)
                                        ], 64));
                                    }), 256)),
                                    (_ctx.$slots.extra)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                            _renderSlot(_ctx.$slots, "extra")
                                        ]))
                                        : _createCommentVNode("", true),
                                    (props.loading)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                            _createVNode(_unref(BaseLoader))
                                        ]))
                                        : _createCommentVNode("", true)
                                ]),
                                _: 3
                            }, 512))
                            : _createCommentVNode("", true),
                        (_unref(opts).length === 0 && _ctx.$slots.empty)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _renderSlot(_ctx.$slots, "empty")
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ], 2)), [
                    [_unref(vClickOutside), close]
                ])
                : _createCommentVNode("", true);
        };
    }
});
