import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["disabled"];
const _hoisted_2 = {
    key: 0,
    class: "base-button__label"
};
import { BUTTON_TYPES } from "@/components/@base/@types";
import { BaseLoader } from "@/components/@base";
import { THEME } from "@/components/@types";
import { reactive, toRef, watch } from "vue";
const __default__ = { name: 'BaseButton' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        label: { default: '' },
        disabled: { type: Boolean, default: false },
        type: { default: BUTTON_TYPES.default },
        theme: { default: THEME.light },
        func: { type: [Function, null], default: null },
        loading: { type: Boolean, default: false }
    },
    setup(__props) {
        const props = __props;
        const data = reactive({
            disabled: props.disabled
        });
        const disabled = toRef(props, 'disabled');
        const act = async () => {
            if (props.func) {
                data.disabled = true;
                await props.func();
                data.disabled = false;
            }
        };
        watch(disabled, (next) => {
            data.disabled = next;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("button", {
                class: _normalizeClass(["base-button", [props.theme, `type-${props.type}`]]),
                type: "button",
                disabled: data.disabled,
                onClick: act
            }, [
                _renderSlot(_ctx.$slots, "default"),
                (props.label && !props.loading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(props.label), 1))
                    : _createCommentVNode("", true),
                (props.loading)
                    ? (_openBlock(), _createBlock(_unref(BaseLoader), {
                        key: 1,
                        class: "base-button__loader"
                    }))
                    : _createCommentVNode("", true)
            ], 10, _hoisted_1));
        };
    }
});
