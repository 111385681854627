export var THEME;
(function (THEME) {
    THEME["light"] = "light";
    THEME["dark"] = "dark";
})(THEME || (THEME = {}));
export var MODALS_TYPES;
(function (MODALS_TYPES) {
    MODALS_TYPES["modal"] = "modal";
    MODALS_TYPES["objection"] = "objection";
})(MODALS_TYPES || (MODALS_TYPES = {}));
export var SECTION_TYPES;
(function (SECTION_TYPES) {
    SECTION_TYPES["sections"] = "sections";
    SECTION_TYPES["objections"] = "objections";
})(SECTION_TYPES || (SECTION_TYPES = {}));
export var STRATEGY_TYPES;
(function (STRATEGY_TYPES) {
    STRATEGY_TYPES["and"] = "and";
    STRATEGY_TYPES["or"] = "or";
})(STRATEGY_TYPES || (STRATEGY_TYPES = {}));
export var SECTORS_TYPES;
(function (SECTORS_TYPES) {
    SECTORS_TYPES["client"] = "client";
    SECTORS_TYPES["comment"] = "comment";
    SECTORS_TYPES["modals"] = "modals";
    SECTORS_TYPES["linkedEntities"] = "linkedEntities";
    SECTORS_TYPES["status"] = "status";
})(SECTORS_TYPES || (SECTORS_TYPES = {}));
export var CLIENT_TYPES;
(function (CLIENT_TYPES) {
    CLIENT_TYPES["client"] = "client";
    CLIENT_TYPES["contact"] = "contact";
})(CLIENT_TYPES || (CLIENT_TYPES = {}));
export var CONSTRUCTOR_MENU_GROUPS;
(function (CONSTRUCTOR_MENU_GROUPS) {
    CONSTRUCTOR_MENU_GROUPS["default"] = "default";
    CONSTRUCTOR_MENU_GROUPS["section"] = "section";
    CONSTRUCTOR_MENU_GROUPS["objection"] = "objection";
    CONSTRUCTOR_MENU_GROUPS["step"] = "step";
    CONSTRUCTOR_MENU_GROUPS["node"] = "node";
    CONSTRUCTOR_MENU_GROUPS["edge"] = "edge";
})(CONSTRUCTOR_MENU_GROUPS || (CONSTRUCTOR_MENU_GROUPS = {}));
export var NODE_TYPES;
(function (NODE_TYPES) {
    NODE_TYPES["rectangle"] = "rectangle";
    NODE_TYPES["circle"] = "circle";
    NODE_TYPES["diamond"] = "diamond";
})(NODE_TYPES || (NODE_TYPES = {}));
